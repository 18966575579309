import { FilterType, DataType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const AreaPercentageMap = {
  id: 0,
  name: i18n.t('workedAreaReport.Summary.Maps.WorkedAreaTitle'),
  groups: [
    {
      name: 'workedPercentage',
      title: i18n.t('workedAreaReport.Summary.Maps.WorkedAreaGroupTitle'),
      measure: undefined,
      referenceByInterval: true,
      series: [],
      references: [
        {
          name: '10-percent',
          color: '#F4F2D9',
          isInterval: true,
          intervalStart: 0,
          intervalEnd: 10
        },
        {
          name: '20-percent',
          color: '#ECE6C5',
          isInterval: true,
          intervalStart: 10,
          intervalEnd: 20
        },
        {
          name: '30-percent',
          color: '#E5D7B2',
          isInterval: true,
          intervalStart: 20,
          intervalEnd: 30
        },
        {
          name: '40-percent',
          color: '#DCC69F',
          isInterval: true,
          intervalStart: 30,
          intervalEnd: 40
        },
        {
          name: '50-percent',
          color: '#D4B28D',
          isInterval: true,
          intervalStart: 40,
          intervalEnd: 50
        },
        {
          name: '60-percent',
          color: '#CA9D7C',
          isInterval: true,
          intervalStart: 50,
          intervalEnd: 60
        },
        {
          name: '70-percent',
          color: '#C0876B',
          isInterval: true,
          intervalStart: 60,
          intervalEnd: 70
        },
        {
          name: '80-percent',
          color: '#B56F5B',
          isInterval: true,
          intervalStart: 70,
          intervalEnd: 80
        },
        {
          name: '90-percent',
          color: '#AA574B',
          isInterval: true,
          intervalStart: 80,
          intervalEnd: 90
        },
        {
          name: '100-percent',
          color: '#9E3E3C ',
          isInterval: true,
          intervalStart: 90
        }
      ],
      exclusive: true,
      displayOnStart: true
    }
  ],
  filters: [],
  info: {}
}

const AreaWorkFrontMap = {
  id: 0,
  name: i18n.t('workedAreaReport.Summary.Maps.HarvestFrontTitle'),
  groups: [
    {
      name: 'workedPercentage',
      title: i18n.t('workedAreaReport.Summary.Maps.HarvestFrontGroupTitle'),
      measure: undefined,
      referenceByInterval: false,
      series: [],
      references: [],
      exclusive: true,
      displayOnStart: true
    }
  ],
  filters: [],
  info: {}
}

const AreaSummaryHeaders = [
  {
    text: i18n.t('workedAreaReport.Summary.Headers.Farm'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'farm'
  },
  {
    text: i18n.t('workedAreaReport.Summary.Headers.EcologicalUnit'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'ecologicalUnit'
  },
  {
    text: i18n.t('workedAreaReport.Summary.Headers.DriveUnit'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'driveUnitName'
  },
  {
    text: i18n.t('workedAreaReport.Detail.Headers.HarvestFront'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'workFrontName'
  },
  {
    text: i18n.t('workedAreaReport.Detail.Headers.Harvester'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'thingName'
  },
  {
    text: i18n.t('workedAreaReport.Detail.Headers.Activity'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.string,
    value: 'activityName'
  },
  {
    text: i18n.t('workedAreaReport.Summary.Headers.TotalArea'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'totalArea'
  },
  {
    text: i18n.t('workedAreaReport.WorkedArea'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'workedArea'
  },
  {
    text: i18n.t('workedAreaReport.NotWorkedArea'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'notWorkedArea'
  },
  {
    text: i18n.t('workedAreaReport.Summary.Headers.WorkedPercentage'),
    align: 'left',
    filterType: FilterType.textField,
    selected: true,
    sortable: true,
    type: DataType.number,
    value: 'workedAreaPercentage'
  }
]

const DetailSpeedMap = {
  id: 0,
  name: i18n.t('workedAreaReport.Detail.Maps.SpeedTitle'),
  groups: [
    {
      name: 'workedPercentage',
      title: i18n.t('workedAreaReport.Summary.Maps.WorkedAreaGroupTitle'),
      measure: undefined,
      referenceByInterval: false,
      series: [],
      references: [],
      exclusive: true,
      displayOnStart: true
    },
    {
      name: i18n.t('workedAreaReport.Detail.Maps.SpeedTitle'),
      title: i18n.t('workedAreaReport.Detail.Maps.SpeedTitle'),
      measure: undefined,
      referenceByInterval: true,
      series: [],
      references: [
        {
          name: 'lento',
          color: '#FFF933',
          isInterval: true,
          intervalStart: 0,
          intervalEnd: 4
        },
        {
          name: 'normal',
          color: '#80FF33',
          isInterval: true,
          intervalStart: 4,
          intervalEnd: 8
        },
        {
          name: 'rapido',
          color: '#F90611',
          isInterval: true,
          intervalStart: 8,
          intervalEnd: 100
        }
      ],
      exclusive: true,
      displayOnStart: true
    }
  ],
  filters: [
    {
      label: i18n.t('workedAreaReport.Detail.Headers.State'),
      values: [],
      position: 0
    }
  ],
  info: {}
}

const DetailStateMap = (references) => {
  return {
    id: 0,
    name: i18n.t('workedAreaReport.Detail.Maps.StateTitle'),
    groups: [
      {
        name: 'workedPercentage',
        title: i18n.t('workedAreaReport.Summary.Maps.WorkedAreaGroupTitle'),
        measure: undefined,
        referenceByInterval: false,
        series: [],
        references: [],
        exclusive: true,
        displayOnStart: true
      },
      {
        name: i18n.t('workedAreaReport.Detail.Maps.StateTitle'),
        title: i18n.t('workedAreaReport.Detail.Maps.StateTitle'),
        measure: undefined,
        referenceByInterval: false,
        series: [],
        references,
        exclusive: true,
        displayOnStart: true
      }
    ],
    filters: [],
    info: {}
  }
}

const DetailHeaders = (stateOptions) => {
  return [
    {
      text: i18n.t('workedAreaReport.Detail.Headers.DriveUnit'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'driveUnitName'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.HarvestFront'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'workFrontName'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Harvester'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'thingName'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Activity'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'activityName'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Driver'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'driverName'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Date'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'date'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Time'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'time'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.State'),
      align: 'left',
      filterType: FilterType.multiSelect,
      options: stateOptions,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'thingStateName'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Speed'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'speed'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Rpm'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.number,
      value: 'rpm'
    },
    {
      text: i18n.t('workedAreaReport.Detail.Headers.Position'),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.array,
      value: 'position'
    }
  ]
}

export default {
  AreaPercentageMap,
  AreaWorkFrontMap,
  AreaSummaryHeaders,
  DetailSpeedMap,
  DetailStateMap,
  DetailHeaders
}
