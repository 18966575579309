/* eslint-disable indent */
import workedAreaReportApi from '@/api/workedAreaReport.api'
import { getChildrenFromList, getTimeOffset } from '@/tools/functions'
import workedAreaConstants from '@/constants/workedAreaConstants'
import { Axes, Chart, ChartType, Dataset, Options, ReportConversionUtil, Util, ValueText } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'
import moment from 'moment'

const getAreaReportByDriveUnits = async (driveUnits, types, activities, sectors, from, to, timeFormat) => {
    try {
        const driveUnitsExtracted = []
        getChildrenFromList(driveUnits, driveUnitsExtracted)
        const driveUnitsId = driveUnitsExtracted.map(du => du.id)
        const sectorId = sectors.length === 1 ? sectors[0].id : null
        const response = await workedAreaReportApi.getSummaryReportByDriveUnitsDirect(
            driveUnitsId, types.map(t => t.id), activities.map(a => a.id), sectorId, from, to, timeFormat)
        return processSummaryData(response.data, from, to)
    } catch (error) {
        console.error(error)
        return {
            data: [],
            headers: [],
            charts: [],
            maps: [],
            driveUnitPolygons: {}
        }
    }
}

const getAreaReportByDriveUnitsDeferred = async (
    driveUnits, types, activities, sectors, from, to,
    dateAndTimeRange, dateAndTimeRangeCustomType, route, timeFormat, filterSwitch) => {
    const filters = {
        driveUnits,
        types,
        activities,
        sector: sectors,
        from,
        to,
        dateAndTimeRange,
        dateAndTimeRangeCustomType,
        route,
        timeFormat,
        filterSwitch
    }
    try {
        const driveUnitsExtracted = []
        getChildrenFromList(driveUnits, driveUnitsExtracted)
        const driveUnitsId = driveUnitsExtracted.map(du => du.id)
        const sectorId = sectors.length === 1 ? sectors[0].id : null
        await workedAreaReportApi.getSummaryReportByDriveUnits(
            driveUnitsId, types.map(t => t.id), activities.map(a => a.id), sectorId, from, to, timeFormat, route, filters)
    } catch (error) {
        console.error(error)
    }
    return null
}

const getAreaReportByThings = async (from, to, things, types, activities, sectors, timeFormat) => {
    try {
        const thingsExtracted = []
        getChildrenFromList(things, thingsExtracted)
        const sectorId = sectors.length === 1 ? sectors[0].id : null
        const response = await workedAreaReportApi.getSummaryReportByThingsDirect(
            from, to, thingsExtracted, types.map(t => t.id), activities.map(a => a.id), sectorId, timeFormat)
        return processSummaryData(response.data, from, to)
    } catch (error) {
        console.error(error)
        return {
            data: [],
            headers: [],
            charts: [],
            maps: [],
            driveUnitPolygons: {}
        }
    }
}

const getAreaReportByThingsDeferred = async (
    things, types, activities, sectors, from, to,
    dateAndTimeRange, dateAndTimeRangeCustomType, route, timeFormat, filterSwitch) => {
    const filters = {
        things,
        types,
        activities,
        sector: sectors,
        from,
        to,
        dateAndTimeRange,
        dateAndTimeRangeCustomType,
        timeFormat,
        route,
        filterSwitch
    }
    try {
        const thingsExtracted = []
        getChildrenFromList(things, thingsExtracted)
        const sectorId = sectors.length === 1 ? sectors[0].id : null
        await workedAreaReportApi.getSummaryReportByThings(
            thingsExtracted, types.map(t => t.id), activities.map(a => a.id), sectorId, from, to,
            timeFormat, route, filters)
    } catch (error) {
        console.error(error)
    }
    return null
}

const getDetail = async (driveUnitId, from, to, typesId, activitiesId, things, sectors) => {
    try {
        let thingsExtracted = []
        getChildrenFromList(things, thingsExtracted)
        thingsExtracted = thingsExtracted.length > 0 ? thingsExtracted : null
        const sectorId = sectors.length === 1 ? sectors[0].id : null
        const response = await workedAreaReportApi.getDetailReport(
            driveUnitId, from, to, typesId, activitiesId, thingsExtracted, sectorId)
        const data = response.data

        const { mapStateReferences, headerStateOptions } = await processAreaDetail(data)
        const result = {
            headers: workedAreaConstants.DetailHeaders(headerStateOptions),
            data,
            maps: getAreaMapsForDetail(mapStateReferences),
            mapStateReferences
        }

        return result
    } catch (error) {
        console.error(error)
        return {
            data: [],
            headers: [],
            maps: [],
            mapStateReferences: []
        }
    }
}

/**
 * Procesar los datos del resumen del reporte
 * @param {*} data
 */
const processSummaryData = (data, from, to) => {
    const result = {}
    const offset = getTimeOffset()
    const lang = localStorage.getItem('locale')

    // se procesan los datos del reporte
    const processedSummary = processAreaSummary(data, lang)

    // porcentajes de área total trabajada y no trabajada
    const total = processedSummary.workedArea + processedSummary.notWorkedArea
    const percentageWorkedArea = Util.roundDecimals(processedSummary.workedArea * 100 / total)
    const percentageNotWorkedArea = Util.roundDecimals(processedSummary.notWorkedArea * 100 / total)

    // headers
    result.headers = workedAreaConstants.AreaSummaryHeaders

    // datos
    result.data = processedSummary.reportData

    // gráficos
    result.charts = [
        getWorkedAreaSummaryChart(processedSummary.workedArea, processedSummary.notWorkedArea, percentageWorkedArea, percentageNotWorkedArea, from, to, offset),
        getFarmSummaryChart(processedSummary.farmMap, from, to, offset),
        getWorkFrontSummaryChart(processedSummary.workFrontMap, from, to, offset)
    ]

    // mapas
    result.maps = getAreaMaps(
        processedSummary.seriesWorkedArea,
        processedSummary.seriesWorkFront,
        processedSummary.mapWorkFrontReferences,
        lang
    )

    // polígonos de las UM para el detalle
    result.driveUnitPolygons = processedSummary.driveUnitPolygons

    return result
}

/**
 * Procesar los datos para el reporte de área trabajada (resúmen)
 * @param data
 * @returns
 */
const processAreaSummary = (data) => {
    const reportData = []
    const seriesWorkedArea = []
    const seriesWorkFront = []
    const mapWorkFrontReferences = []
    const farmMap = {}
    const workFrontMap = {}
    let workedArea = 0
    let notWorkedArea = 0
    const driveUnitPolygons = {}

    data.forEach(d => {
        // WORKED AREA CHART
        workedArea += d.workedArea
        notWorkedArea += d.areaNotWorked || 0

        // área trabajda sin redondear
        const workedAreaRaw = d.workedArea

        d.workedArea = Util.roundDecimals(d.workedArea)
        d.areaNotWorked = d.areaNotWorked ? Util.roundDecimals(d.areaNotWorked) : undefined
        d.workedAreaPercentage = d.workedAreaPercentage ? Util.roundDecimals(d.workedAreaPercentage) : undefined
        d.workFront = []
        // Por si no llegan los datos para el mapa
        if (!d.driveUnitData) {
            return
        } else if (!d.driveUnitData.properties) {
            return
        }

        // REPORT DATA
        const row = {
            farm: d.driveUnitData.properties.farm || i18n.t('workedAreaReport.NoFarm'),
            ecologicalUnit: d.driveUnitData.properties.ecologicalUnit || i18n.t('workedAreaReport.NoEcologicalUnit'),
            driveUnitId: d.driveUnitId || 0,
            driveUnitName: d.driveUnitData.properties.Name === 'NO_DRIVE_UNIT'
                ? i18n.t('workedAreaReport.NoDriveUnit')
                : d.driveUnitData.properties.Name,
            totalArea: new ValueText(d.totalArea, `${d.totalArea}`.includes('.') ? `${d.totalArea}` : `${d.totalArea}.0`),
            workedArea: new ValueText(d.workedArea, `${d.workedArea}`.includes('.') ? `${d.workedArea}` : `${d.workedArea}.0`),
            notWorkedArea: d.areaNotWorked ? new ValueText(d.areaNotWorked, `${d.areaNotWorked}`.includes('.') ? `${d.areaNotWorked}` : `${d.areaNotWorked}.0`) : undefined,
            workedAreaPercentage: d.workedAreaPercentage ? 
                new ValueText(d.workedAreaPercentage, (`${d.workedAreaPercentage}`.includes('.') ? `${d.workedAreaPercentage}` : `${d.workedAreaPercentage + '.0'}`) + '%') 
                : undefined
        }
        if (d.data) {
            d.data.forEach(t => {
                const dataRow = Object.assign({}, row)

                // área trabajda sin redondear
                const thingWorkedAreaRaw = t.workedArea

                t.workedArea = Util.roundDecimals(t.workedArea)
                t.areaNotWorked = t.areaNotWorked ? Util.roundDecimals(t.areaNotWorked) : undefined
                t.workedAreaPercentage = t.workedAreaPercentage
                    ? Util.roundDecimals(t.workedAreaPercentage) : undefined

                dataRow.workedArea = new ValueText(t.workedArea, `${t.workedArea}`.includes('.') ? `${t.workedArea}` : `${t.workedArea}.0`)
                dataRow.notWorkedArea = t.areaNotWorked ? new ValueText(t.areaNotWorked, `${t.areaNotWorked}`.includes('.') ? `${t.areaNotWorked}` : `${t.areaNotWorked}.0`) : undefined
                dataRow.workedAreaPercentage = t.workedAreaPercentage
                    ? new ValueText(d.workedAreaPercentage, `${d.workedAreaPercentage}`.includes('.') ? `${d.workedAreaPercentage}` : `${d.workedAreaPercentage + '.0'}`) : undefined
                dataRow.activityId = t.activityId
                dataRow.activityName = t.activityName

                if (t.thingId) {
                    dataRow.thingId = t.thingId
                    dataRow.thingName = t.thingName
                    dataRow.workFrontName = t.workFrontName || i18n.t('workedAreaReport.NoHarvestFront')
                    if (!d.workFront.includes(dataRow.workFrontName)) { d.workFront.push(dataRow.workFrontName) }
                    // WORK FRONT CHART
                    if (!workFrontMap[dataRow.workFrontName]) {
                        workFrontMap[dataRow.workFrontName] = {
                            workFront: dataRow.workFrontName,
                            workedArea: thingWorkedAreaRaw
                        }
                    } else {
                        workFrontMap[dataRow.workFrontName].workedArea += thingWorkedAreaRaw
                    }
                }

                if (t.thingTypeId) {
                    dataRow.thingTypeId = t.thingTypeId
                    dataRow.thingTypeName = t.thingTypeName
                    if (t.workFronts) {
                        t.workFronts.forEach(wf => {
                            if (!d.workFront.includes(wf.workFrontName)) { d.workFront.push(wf.workFrontName) }
                            // WORK FRONT CHART
                            if (!workFrontMap[wf.workFrontName]) {
                                workFrontMap[wf.workFrontName] = {
                                    workFront: wf.workFrontName,
                                    workedArea: wf.workedArea
                                }
                            } else {
                                workFrontMap[wf.workFrontName].workedArea += wf.workedArea
                            }
                        })
                    }
                }

                reportData.push(dataRow)
            })
        } else {
            reportData.push(row)
        }

        // FARM CHART
        if (d.driveUnitData.properties.farm) {
            if (!farmMap[d.driveUnitData.properties.farm]) {
                farmMap[d.driveUnitData.properties.farm] = {
                    farm: d.driveUnitData.properties.farm,
                    workedArea: workedAreaRaw,
                    areaNotWorked: d.areaNotWorked
                }
            } else {
                farmMap[d.driveUnitData.properties.farm].workedArea += workedAreaRaw
                farmMap[d.driveUnitData.properties.farm].areaNotWorked += d.areaNotWorked
            }
        } else {
            if (!farmMap['NO_FARM']) {
                farmMap['NO_FARM'] = {
                    farm: i18n.t('workedAreaReport.NoFarm'),
                    workedArea: workedAreaRaw,
                    areaNotWorked: 0
                }
            } else {
                farmMap['NO_FARM'].workedArea += workedAreaRaw
            }
        }

        // MAP
        if (d.driveUnitData.geometry) {
            const serie1 = { type: 'POLYGON', points: [] }
            const serie2 = { type: 'POLYGON', points: [] }
            const serie3 = { type: 'POLYGON', points: [] }
            const value1 = d.workedAreaPercentage ? d.workedAreaPercentage : 0
            const value2 = d.workFront.toString()
            const value3 = d.driveUnitData.properties.Name
            d.driveUnitData.geometry.coordinates[0].forEach(p => {
                serie1.points.push({
                    lat: p[1],
                    lng: p[0],
                    value: value1,
                    filters: [],
                    tag: `${d.driveUnitData.properties.Name} - ${Util.roundDecimals(value1)}%`
                })
                serie3.points.push({
                    lat: p[1],
                    lng: p[0],
                    value: value3,
                    filters: [],
                    tag: `${d.driveUnitData.properties.Name} - ${Util.roundDecimals(value1)}%`
                })
                serie2.points.push({ lat: p[1], lng: p[0], value: value2, filters: [], tag: d.driveUnitData.properties.Name })
            })
            seriesWorkedArea.push(serie1)
            driveUnitPolygons[d.driveUnitId] = {
                serie: serie3,
                reference: {
                    name: value3,
                    color: 'noColor',
                    isInterval: false,
                    title: value3
                }
            }
            seriesWorkFront.push(serie2)

            if (!mapWorkFrontReferences.find(ref => ref.name === value2)) {
                const color = Util.pickColor(mapWorkFrontReferences.map(ref => ref.color))
                mapWorkFrontReferences.push({
                    name: value2 || i18n.t('workedAreaReport.NoHarvestFront'),
                    color,
                    isInterval: false,
                    title: value2,
                    intervalStart: undefined,
                    intervalEnd: undefined
                })
            }
        }
    })
    return {
        reportData,
        seriesWorkedArea,
        seriesWorkFront,
        mapWorkFrontReferences,
        workedArea: Util.roundDecimals(workedArea),
        notWorkedArea: Util.roundDecimals(notWorkedArea),
        farmMap,
        workFrontMap,
        driveUnitPolygons
    }
}

/**
 * Obtener mapas para el resumen
 * @param seriesWorkedArea
 * @param seriesWorkFront
 * @param referencesWorkFront
 * @param lang
 * @returns
 */
const getAreaMaps = (seriesWorkedArea, seriesWorkFront, referencesWorkFront) => {
    const map1 = workedAreaConstants.AreaPercentageMap
    const map2 = workedAreaConstants.AreaWorkFrontMap
    map1.groups[0].series = seriesWorkedArea
    map2.groups[0].series = seriesWorkFront
    map2.groups[0].references = referencesWorkFront
    map1.id = moment().unix()
    map2.id = moment().unix() + 1
    return [map1, map2]
}

/**
 * Generar gráfico por fincas
 * @param data
 * @param from
 * @param to
 * @param offset
 * @returns
 */
const getFarmSummaryChart = (data, from, to, offset) => {
    let datasets = []
    const labels = []

    const farms = Object.values(data)
    if (farms.length > 0) {
        const datasetWA = new Dataset()
        datasetWA.backgroundColor = '#66BB6A'
        datasetWA.label = i18n.t('workedAreaReport.WorkedArea')

        const datasetNWA = new Dataset()
        datasetNWA.backgroundColor = '#EF5350'
        datasetNWA.label = i18n.t('workedAreaReport.NotWorkedArea')

        farms.forEach((d) => {
            datasetWA.data.push(Util.roundDecimals(d.workedArea))
            datasetNWA.data.push(Util.roundDecimals(d.areaNotWorked))
            // labels
            labels.push(d.farm)
        })

        datasets = [datasetWA, datasetNWA]
    }

    return getFarmChart(datasets, labels, from, to, offset)
}

/**
 * Generar gráfico de fincas a partir de un dataset
 * @param {*} dataset
 * @param {*} labels
 * @param {*} from
 * @param {*} to
 * @param {*} offset
 * @returns
 */
 const getFarmChart = (datasets, labels, from, to, offset) => {
    const yAxes = new Axes(ChartType.BAR)
    const xAxes = new Axes(ChartType.BAR)
    const options = new Options()
    const chart = new Chart()
    // id
    /*
    const milliseconds = moment().unix()
    chart.id = `chartFarm-${milliseconds}`
    */
    chart.id = `chartFarm`
    // nombre
    chart.name = i18n.t('workedAreaReport.Summary.Charts.FarmTitle')
    // tipo
    chart.type = ChartType.BAR

    yAxes.scaleLabel.labelString = i18n.t('workedAreaReport.Summary.Charts.QuantityLabel')
    xAxes.scaleLabel.labelString = i18n.t('workedAreaReport.Summary.Charts.FarmLabel')
    yAxes.stacked = true
    xAxes.stacked = true

    // Agrego los datasets creados
    chart.data.datasets = datasets

    // labels
    chart.data.labels = labels

    // Options

    // legend
    const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
    const toConversion = ReportConversionUtil.applyTimezone(to, offset)
    const title = fromConversion.dateString + ' ' +
        fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
    options.title.text = title

    options.scales.yAxes.push(yAxes)
    options.scales.xAxes.push(xAxes)
    chart.options = options

    return chart
}

/**
 * Generar gráfico de área trabajada
 * @param workedArea
 * @param notWorkedArea
 * @param from
 * @param to
 * @param offset
 * @returns
 */
const getWorkedAreaSummaryChart = (workedArea, notWorkedArea, workedAreaPercentage, notWorkedAreaPercentage, from, to, offset) => {
    const yAxes = new Axes(ChartType.PIE)
    const xAxes = new Axes(ChartType.PIE)
    const options = new Options()
    options.tooltips.mode = 'point'
    const chart = new Chart()
    const dataset = new Dataset()

    // id
    /*
    const milliseconds = moment().unix()
    chart.id = `chartArea-${milliseconds}`
    */
    chart.id = `chartArea`

    // nombre
    chart.name = i18n.t('workedAreaReport.WorkedArea') +
        ' - Total de hectareas: ' + Util.roundDecimals(workedArea + notWorkedArea)

    // tipo
    chart.type = ChartType.PIE

    // labels
    const labelWorkedArea = `${i18n.t('workedAreaReport.WorkedArea')} (${workedAreaPercentage || 0}%)`
    const labelNotWorkedArea = `${i18n.t('workedAreaReport.NotWorkedArea')} (${notWorkedAreaPercentage || 0}%)`
    chart.data.labels = [
        labelWorkedArea,
        labelNotWorkedArea
    ]

    // Dataset
    dataset.data = [workedArea, notWorkedArea]
    dataset.formattedTooltipData.label = [
        `${Util.roundDecimals(workedArea)} (${workedAreaPercentage || 0}%)`,
        `${Util.roundDecimals(notWorkedArea)} (${notWorkedAreaPercentage || 0}%)`
    ]
    dataset.formattedLabelData = [
        Util.roundDecimals(workedArea),
        Util.roundDecimals(notWorkedArea)
    ]

    // Color
    dataset.backgroundColor = ['#66BB6A', '#EF5350']

    // Agrego el dataset creado
    chart.data.datasets.push(dataset)

    // legend
    const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
    const toConversion = ReportConversionUtil.applyTimezone(to, offset)
    const title = fromConversion.dateString + ' ' +
        fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
    options.title.text = title

    options.scales.yAxes.push(yAxes)
    options.scales.xAxes.push(xAxes)
    chart.options = options
    return chart
}

/**
 * Generar gráfico de frentes de trabajo del REPORTE
 * @param data
 * @param from
 * @param to
 * @param offset
 * @returns
 */
const getWorkFrontSummaryChart = (data, from, to, offset) => {
    let dataset = null
    const labels = []

    const workFronts = Object.values(data)
    if (workFronts.length > 0) {
        dataset = new Dataset()
        dataset.label = i18n.t('workedAreaReport.WorkedArea')

        // cargo los datos al dataset
        workFronts.forEach((d) => {
            if (d.workFront) {
                dataset.data.push(Util.roundDecimals(d.workedArea))
                labels.push(d.workFront || i18n.t('workedAreaReport.NoHarvestFront'))
            }
        })

        // color
        dataset.backgroundColor = '#66BB6A'
        /*
        let chartColor
        dataset.backgroundColor = []
        chartColor = Util.pickColor(dataset.backgroundColor)
        for (let i = 0; i <= dataset.data.length - 1; i++) {
            dataset.backgroundColor.push(chartColor)
        }
        */
    }

    return getWorkFrontChart(dataset, labels, from, to, offset)
}

/**
 * Generar gráfico de frentes de trabajo a partir de un dataset
 * @param {*} dataset
 * @param {*} labels
 * @param {*} from
 * @param {*} to
 * @param {*} offset
 * @returns
 */
const getWorkFrontChart = (dataset, labels, from, to, offset) => {
    const yAxes = new Axes(ChartType.BAR)
    const xAxes = new Axes(ChartType.BAR)
    const options = new Options()
    const chart = new Chart()
    // id
    /*
    const milliseconds = moment().unix()
    chart.id = `chartFront-${milliseconds}`
    */
    chart.id = `chartFront`
    // nombre
    chart.name = i18n.t('workedAreaReport.Summary.Charts.HarvestFrontTitle')
    // tipo
    chart.type = ChartType.BAR

    yAxes.scaleLabel.labelString = i18n.t('workedAreaReport.Summary.Charts.QuantityLabel')
    xAxes.scaleLabel.labelString = i18n.t('workedAreaReport.Summary.Charts.HarvestFrontLabel')

    // Agrego el dataset creado
    if (dataset != null) {
        chart.data.datasets.push(dataset)
    }

    // labels
    chart.data.labels = labels

    // Options

    // legend
    const fromConversion = ReportConversionUtil.applyTimezone(from, offset)
    const toConversion = ReportConversionUtil.applyTimezone(to, offset)
    const title = fromConversion.dateString + ' ' +
        fromConversion.timeString + ' - ' + toConversion.dateString + ' ' + toConversion.timeString
    options.title.text = title

    options.scales.yAxes.push(yAxes)
    options.scales.xAxes.push(xAxes)
    chart.options = options

    return chart
}

/**
 * Mapas para el detalle del reporte de área trabajada
 * @param {*} mapStateReferences
 * @returns
 */
const getAreaMapsForDetail = (mapStateReferences) => {
    const detailStateMap = workedAreaConstants.DetailStateMap(mapStateReferences)
    const detailSpeedMap = workedAreaConstants.DetailSpeedMap
    return { detailStateMap, detailSpeedMap }
}

/**
 * Procesa los datos para el detalle del reporte de área trabajada
 * @param {*} data
 * @returns
 */
const processAreaDetail = (data) => {
    const timeOffset = getTimeOffset()
    const lang = localStorage.getItem('locale')

    const mapStateReferences = []
    const headerStateOptions = []
    const noState = i18n.t('workedAreaReport.Detail.Maps.UndefinedState')

    data.forEach(d => {
        if (timeOffset != null) {
            const { dateString, timeString } = ReportConversionUtil.applyTimezone(d.timestamp, timeOffset)
            d.date = dateString
            d.time = timeString
        }

        d.speed = new ValueText(d.speed, ReportConversionUtil.speedToString(d.speed, d.direction))
        d.position = new ValueText(d.position, JSON.stringify(d.position))
        d.rpm = new ValueText(parseInt(d.rpm, 10) || 0, d.rpm || undefined)

        const state = d.thingStateName ? d.thingStateName[lang] : undefined
        d.thingStateName = state

        if (mapStateReferences.filter(ref => ref.name === state).length === 0) {
            let color

            if (d.thingStateColor) {
                color = d.thingStateColor
            } else {
                color = '#f44336'
            }

            mapStateReferences.push({
                name: state,
                color,
                isInterval: false,
                title: state
            })
            headerStateOptions.push(state)
        }

        if (state === noState) {
            d.thingStateName = null
        }
    })
    return { mapStateReferences, headerStateOptions }
}

/**
 * Generar dashboard de área total trabajada
 * @param {*} sector
 * @param {*} activities
 * @param {*} types
 * @param {*} from
 * @param {*} to
 * @param {*} range
 * @returns
 */
const getDashboardTotal = async (sector, activities, types, from, to, range) => {
    try {
        const response = await workedAreaReportApi.getWorkedAreaFarmsAndTotal(sector, activities, types, from, to, range)
        const { data, precalc } = response.data

        // offset
        const offset = getTimeOffset()
        const lang = localStorage.getItem('locale')

        // generación del gráfico
        let result
        if (precalc) {
            // TODO
            result = getWorkedAreaSummaryChart(data.totalWorked, data.totalNotWorked, data.totalWorkedPercentage, data.totalNotWorkedPercentage, data.from, data.to, offset)
        } else {
            const processedSummary = processAreaSummary(data, lang)
            const total = processedSummary.workedArea + processedSummary.notWorkedArea
            const percentageWorkedArea = Util.roundDecimals(processedSummary.workedArea * 100 / total)
            const percentageNotWorkedArea = Util.roundDecimals(processedSummary.notWorkedArea * 100 / total)
            result = getWorkedAreaSummaryChart(processedSummary.workedArea, processedSummary.notWorkedArea, percentageWorkedArea, percentageNotWorkedArea, from, to, offset)
        }

        return result
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * Generar dashboard de área trabajada por fincas
 * @param {*} sector
 * @param {*} activities
 * @param {*} types
 * @param {*} from
 * @param {*} to
 * @param {*} range
 * @returns
 */
 const getDashboardFarms = async (sector, activities, types, from, to, range) => {
    try {
        const response = await workedAreaReportApi.getWorkedAreaFarmsAndTotal(sector, activities, types, from, to, range)
        const { data, precalc } = response.data

        // offset
        const offset = getTimeOffset()
        const lang = localStorage.getItem('locale')

        // generación del gráfico
        let result
        if (precalc) {
            let datasets = []
            const labels = []

            if (data.farms.length > 0) {
                const datasetWA = new Dataset()
                datasetWA.backgroundColor = '#66BB6A'
                datasetWA.label = i18n.t('workedAreaReport.WorkedArea')

                const datasetNWA = new Dataset()
                datasetNWA.backgroundColor = '#EF5350'
                datasetNWA.label = i18n.t('workedAreaReport.NotWorkedArea')

                data.farms.forEach(farm => {
                    datasetWA.data.push(Util.roundDecimals(farm.worked))
                    datasetNWA.data.push(Util.roundDecimals(farm.notWorked))
                    // labels
                    labels.push(farm.name || i18n.t('workedAreaReport.NoFarm'))
                })

                datasets = [datasetWA, datasetNWA]
            }

            return getFarmChart(datasets, labels, data.from, data.to, offset)
        } else {
            const processedSummary = processAreaSummary(data, lang)
            result = getFarmSummaryChart(processedSummary.farmMap, from, to, offset)
        }

        return result
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * Generar dashboard de área trabajada por frentes de trabajo
 * @param {*} sector
 * @param {*} activities
 * @param {*} types
 * @param {*} from
 * @param {*} to
 * @param {*} range
 * @returns
 */
 const getDashboardWorkFronts = async (sector, activities, types, from, to, range) => {
    try {
        const response = await workedAreaReportApi.getWorkedAreaWorkFronts(sector, activities, types, from, to, range)
        const { data, precalc } = response.data

        // offset
        const offset = getTimeOffset()
        const lang = localStorage.getItem('locale')

        // generación del gráfico
        let result
        if (precalc) {
            let dataset = null
            const labels = []
            if (data.workFronts.length > 0) {
                dataset = new Dataset()
                dataset.label = i18n.t('workedAreaReport.WorkedArea')

                // cargo los datos al dataset
                data.workFronts.forEach((workFront) => {
                    dataset.data.push(Util.roundDecimals(workFront.worked))
                    labels.push(workFront.name || i18n.t('workedAreaReport.NoHarvestFront'))
                })

                // color
                dataset.backgroundColor = '#66BB6A'
                /*
                let chartColor
                dataset.backgroundColor = []
                chartColor = Util.pickColor(dataset.backgroundColor)
                for (let i = 0; i <= dataset.data.length - 1; i++) {
                    dataset.backgroundColor.push(chartColor)
                }
                */
            }

            result = getWorkFrontChart(dataset, labels, data.from, data.to, offset)
        } else {
            const processedSummary = processAreaSummary(data, lang)
            result = getWorkFrontSummaryChart(processedSummary.workFrontMap, from, to, offset)
        }

        return result
    } catch (error) {
        console.error(error)
        return null
    }
}

export default {
    getAreaReportByDriveUnits,
    getAreaReportByDriveUnitsDeferred,
    getAreaReportByThings,
    getAreaReportByThingsDeferred,
    getDetail,
    processSummaryData,
    getDashboardTotal,
    getDashboardFarms,
    getDashboardWorkFronts
}
