import service from '@/middleware'

const workedAreaReportApi = {
  async getSummaryReportByDriveUnits (
    driveUnitsId, typesId, activitiesId, sectorId, from, to, timeFormat, route, filters) {
    const body = {
      reportQueryFilters: {
        driveUnitsId,
        typesId,
        activitiesId,
        sectorId,
        from,
        to,
        timeFormat,
        route
      },
      filters
    }
    const result = await service.http.post('/api/worked-area', body)
    return result
  },
  async getSummaryReportByDriveUnitsDirect (driveUnitsId, typesId, activitiesId, sectorId, from, to, timeFormat) {
    const body = {
      driveUnitsId,
      from,
      to,
      activitiesId,
      typesId,
      sectorId,
      timeFormat
    }
    const result = await service.http.post('/api/worked-area/not-deferred', body)
    return result
  },
  async getSummaryReportByThings (
    things, typesId, activitiesId, sectorId, from, to, timeFormat, route, filters) {
    const body = {
      reportQueryFilters: {
        things,
        typesId,
        activitiesId,
        sectorId,
        from,
        to,
        timeFormat,
        route
      },
      filters
    }
    const result = await service.http.post('/api/worked-area', body)
    return result
  },
  async getSummaryReportByThingsDirect (from, to, things, typesId, activitiesId, sectorId, timeFormat) {
    const body = {
      things,
      from,
      to,
      typesId,
      activitiesId,
      sectorId,
      timeFormat
    }
    const result = await service.http.post('/api/worked-area/not-deferred', body)
    return result
  },
  async getDetailReport (driveUnitId, from, to, typesId, activitiesId, things, sectorId) {
    const result = await service.http.post('/api/worked-area/detail', {
      driveUnitId,
      from,
      to,
      typesId,
      activitiesId,
      things,
      sectorId
    })
    return result
  },
  async getWorkedAreaFarmsAndTotal (sector, activities, types, from, to, range) {
    const response = await service.http.post('/api/worked-area/dashboard/farms-and-total', { sector, activities, types, from, to, range })
    return response
  },
  async getWorkedAreaWorkFronts (sector, activities, types, from, to, range) {
    const response = await service.http.post('/api/worked-area/dashboard/work-fronts', { sector, activities, types, from, to, range })
    return response
  }
}

export default workedAreaReportApi
