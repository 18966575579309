import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import Vue from 'vue'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import activityApi from '@/api/activity.api'
import enterpriseApi from '@/api/enterprise.api'
import workedAreaService from '@/business/workedAreaService'
import thingApi from '@/api/thing.api'
import { selectorDateTimeValidation, getDateTimeRange, getTimeRangeNames } from '@/tools/functions'
import { configurationService } from '@/business/configurationService'

// constante de update de gráficos (en minutos)
const REFRESH = 10

export default {
  name: 'WorkedAreaTotalComponent',
  components: {
    DashboardChartContainer
  },
  props: {
    chartComponentId: {
      type: String,
      required: true
    },
    sector: {
      type: String,
      required: false,
      default: () => { return null }
    },
    height: {
      type: String,
      required: false,
      default: () => { return '100%' }
    }
  },
  data: () => ({
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [
        // inicio de zafra
        // {
        //   id: TimeRanges.HARVEST_START,
        //   text: i18n.t('selector.dateAndTime.harvestStart'),
        //   custom: false
        // },
        {
          id: TimeRanges.LAST_HOUR,
          text: i18n.t('selector.dateAndTime.lastHour'),
          custom: false
        },
        {
          id: TimeRanges.LAST_SIX_HOUR,
          text: i18n.t('selector.dateAndTime.lastSixHours'),
          custom: false
        },
        {
          id: TimeRanges.TODAY,
          text: i18n.t('selector.dateAndTime.today'),
          custom: false
        },
        {
          id: TimeRanges.YESTERDAY,
          text: i18n.t('selector.dateAndTime.yesterday'),
          custom: false
        },
        {
          id: TimeRanges.LAST_WEEK,
          text: i18n.t('selector.dateAndTime.lastWeek'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE,
          text: i18n.t('selector.dateAndTime.dailyShift'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE_PREVIOUS,
          text: i18n.t('selector.dateAndTime.previousShift'),
          custom: false
        }
        /*
        {
          id: TimeRanges.CUSTOM,
          text: i18n.t('selector.dateAndTime.custom'),
          custom: true
        }
        */
      ],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [
        {
          text: i18n.t('selector.dateAndTime.customTypes.dateAndTime'),
          value: CustomTimeRangeTypes.DATE_AND_TIME
        },
        {
          text: i18n.t('selector.dateAndTime.customTypes.date'),
          value: CustomTimeRangeTypes.DATE
        }
      ],
      selectedDateAndTimeRange: TimeRanges.ENTERPRISE,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [
        {
          id: 'activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        {
          id: 'type',
          name: i18n.t('headers.thingType'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        }
      ],
      selects: [],
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: false,
      update: false
    },
    timeRange: null,
    timeRangeCustomType: null,
    sinceDate: null,
    sinceTime: null,
    toDate: null,
    toTime: null,
    timestampFrom: null,
    timestampTo: null,
    activities: [],
    types: [],
    currentChartId: 0,
    loading: false,
    chartName: '',
    intervalId: null
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  async mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    let initialLoad = true
    this.loading = true

    // actividades
    const activityResponse = await activityApi.getAllForSelector(this.sector)
    if (Array.isArray(activityResponse.data)) {
      this.chartContainerModel.filters[0].data.cleanAndUpdate(activityResponse.data)
      if (activityResponse.data.length === 1 && activityResponse.data[0].key === 'NO_ACTIVITY') {
        this.chartContainerModel.filters[0].selectedData = this.chartContainerModel.filters[0].data.filter(s => s.key === 'NO_ACTIVITY')
        this.chartContainerModel.filters[0].disabled = true
        this.chartContainerModel.filters[0].hide = true
        await this.activityFilterCallback()
      }
    }

    // llamado a callback de actividades
    this.chartContainerModel.filters[0].selectAction = this.activityFilterCallback.bind(this)

    // fecha personaliza solo para los superadmin
    // SE DESHABILITAN PARA TODOS LOS USERS
    /*
    let admin = JSON.parse(localStorage.getItem('admin'))
    if (admin) {
      this.chartContainerModel.dateAndTimeRanges.push({
        id: TimeRanges.CUSTOM,
        text: i18n.t('selector.dateAndTime.custom'),
        custom: true
      })
      this.chartContainerModel.dateAndTimeRanges.push({
        id: TimeRanges.HARVEST_START,
        text: i18n.t('selector.dateAndTime.harvestStart'),
        custom: false
      })
    }
    */

    // tiempo de jornada diaria
    if (JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      this.chartContainerModel.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE).text = i18n.t('selector.dateAndTime.dailyShift')
    } else {
      const enterpriseTimeSpan = (await enterpriseApi.getEnterpriseCustomTimeSpan()).data
      if (enterpriseTimeSpan) {
        localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
        this.chartContainerModel.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE).text = i18n.t('selector.dateAndTime.dailyShift')
      } else {
        this.chartContainerModel.dateAndTimeRanges.splice(this.chartContainerModel.dateAndTimeRanges.indexOf(x => x.id === TimeRanges.ENTERPRISE) - 1, 1)
        this.chartContainerModel.selectedDateAndTimeRange = TimeRanges.HARVEST_START
      }
    }

    // tiempo de inicio de zafra
    if (!JSON.parse(localStorage.getItem('harvestStartTime'))) {
      const harvestStartTime = (await enterpriseApi.getEnterpriseHarvestStartTime()).data
      if (harvestStartTime) {
        localStorage.setItem('harvestStartTime', JSON.stringify(harvestStartTime))
      } else {
        const havevestStartIndex = this.chartContainerModel.dateAndTimeRanges.indexOf(x => x.id === TimeRanges.HARVEST_START)
        if (havevestStartIndex !== -1) {
          this.chartContainerModel.dateAndTimeRanges.splice(havevestStartIndex - 1, 1)
          this.chartContainerModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
          initialLoad = false
        }
      }
    }

    // configuración
    initialLoad = await this.getConfiguration(initialLoad)

    this.loading = false
    // ejecuto la validación del selector, si es correcta, se carga el gráfico, sino abro el selector
    if (initialLoad) {
      // creo los gráficos
      this.getData(this.chartContainerModel)
    } else {
      this.chartContainerModel.showNavigationPanel = true
    }
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = null
  },
  destroyed () {
  },
  computed: {
    /**
     * Detalles del gráfico (html)
     */
    details () {
      const timeNames = getTimeRangeNames()
      const activities = this.chartContainerModel.filters[0].selectedData.map(a => a.name).toString()
      const types = this.chartContainerModel.filters[1].selectedData.map(t => t.name).toString()
      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('dashboard.workedArea')}</h3>`
      details += `<h5>${this.$t('level')}: ${this.chartName}</h5>`
      details += `<h5>${this.$t('activity')}: ${activities}</h5>`
      details += `<h5>${this.$t('type')}: ${types}</h5>`
      details += `<h5>${this.$t('selector.dateAndTime.label')}: ${timeNames[this.chartContainerModel.selectedDateAndTimeRange]}</h5>`
      return details
    }
  },
  methods: {
    async getData (filters, update = false) {
      configurationService.save(this.chartComponentId,
        {
          activities: filters.filters[0].selectedData,
          types: filters.filters[1].selectedData,
          selectedDateAndTimeRange: filters.selectedDateAndTimeRange,
          selectedDateAndTimeRangeCustomType: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.selectedDateAndTimeRangeCustomType : null,
          sinceDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceDate : null,
          sinceTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceTime : null,
          toDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toDate : null,
          toTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toTime : null
        })
      clearInterval(this.intervalId)
      await this.getDashboardData(filters, update)
      if (filters.selectedDateAndTimeRange !== TimeRanges.CUSTOM) {
        const that = this
        that.intervalId = setInterval(async function () {
          await that.updateAllCharts()
        }, REFRESH * 60 * 1000)
      }
    },
    async getDashboardData (filters, update) {
      this.loading = true
      // backup de filtros
      this.timeRange = filters.selectedDateAndTimeRange
      this.timeRangeCustomType = filters.selectedDateAndTimeRangeCustomType
      this.sinceDate = filters.sinceDate
      this.sinceTime = filters.sinceTime
      this.toDate = filters.toDate
      this.toTime = filters.toTime
      const conversion = getDateTimeRange(filters.selectedDateAndTimeRange, filters.selectedDateAndTimeRangeCustomType,
        filters.sinceDate, filters.sinceTime, filters.toDate, filters.toTime)
      this.timestampFrom = conversion.tFrom
      this.timestampTo = conversion.tTo
      // se guardan los datos de la actividad seleccionada
      this.activities.cleanAndUpdate(filters.filters[0].selectedData)
      const activitiesId = this.activities.map(a => a.id)
      // se guardan los tipos de máquina según la actividad
      this.types.cleanAndUpdate(filters.filters[1].selectedData)
      const typeKeys = this.types.map(t => t.key)
      if (activitiesId && activitiesId.length > 0 && typeKeys && typeKeys.length > 0) {
        // activitiesId, typesIds, this.timestampFrom, this.timestampTo
        const chart = await workedAreaService.getDashboardTotal(null, activitiesId, typeKeys, this.timestampFrom, this.timestampTo, this.timeRange)
        if (chart != null) {
          this.chartContainerModel.charts.cleanAndUpdate([chart])
          this.chartName = this.chartContainerModel.charts[0].name
        }
        // MODIFICACIÓN DE LOS FILTROS
        if (update) {
          this.chartContainerModel.update = true
          const that = this
          Vue.nextTick(function () {
            that.chartContainerModel.update = false
          })
        }
      }
      this.loading = false
    },
    /**
     * Función ejecutada cuando se cambia de tab (se cambia de gráfico)
     * @param {*} data
     */
    changeChart () {
    },
    /**
     * Este método es ejecutado cuando se actualizan todos los gráficos por el timer del "autoreporte"
     */
    async updateAllCharts () {
      await this.getDashboardData(this.chartContainerModel, true)
    },
    /**
     * Para validar el selector de filtros y habilitar/deshabilitar el botón para guardar
     */
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const activitySelected = that.chartContainerModel.filters[0].selectedData
        const typeSelected = that.chartContainerModel.filters[1].selectedData
        const customTimeValidation = selectorDateTimeValidation(that.chartContainerModel.selectedDateAndTimeRange,
          that.chartContainerModel.selectedDateAndTimeRangeCustomType, that.chartContainerModel.customDateTimeValidForm,
          that.chartContainerModel.sinceDate, that.chartContainerModel.sinceTime, that.chartContainerModel.toDate, that.chartContainerModel.toTime)
        that.chartContainerModel.disableSaveButton =
        activitySelected.length === 0 || typeSelected.length === 0 || customTimeValidation
      })
    },
    async activityFilterCallback () {
      if (this.chartContainerModel.filters[0].selectedData.length > 0) {
        this.chartContainerModel.filters[1].selectedData = []
        this.chartContainerModel.filters[1].disabled = false
        const thingTypesResponse =
          await thingApi.getThingTypesByActivity(this.chartContainerModel.filters[0].selectedData.map(a => a.id), this.sector, this.report)
        this.chartContainerModel.filters[1].data.cleanAndUpdate(thingTypesResponse.data)
      } else {
        this.chartContainerModel.filters[1].selectedData = []
        this.chartContainerModel.filters[1].disabled = true
      }
    },
    /**
     * Para obtener la configuración de filtros
     * Esta función se ejecuta en mounted
     * @param {*} init
     * @returns
     */
    async getConfiguration (init) {
      let initialLoad = init
      const config = await configurationService.get(this.chartComponentId)
      if (config) {
        // actividades
        if (this.chartContainerModel.filters[0].data.length > 0) {
          if (config.data.activities && config.data.activities.length > 0) {
            config.data.activities.forEach(activity => {
              if (this.chartContainerModel.filters[0].data.find(a => a.id === activity.id) && !this.chartContainerModel.filters[0].selectedData.find(a => a.id === activity.id)) {
                this.chartContainerModel.filters[0].selectedData.push(activity)
              }
            })
          }

          await this.activityFilterCallback()
          if (config.data.types && config.data.types.length > 0) {
            config.data.types.forEach(type => {
              if (this.chartContainerModel.filters[1].data.find(t => t.id === type.id) && !this.chartContainerModel.filters[1].selectedData.find(t => t.id === type.id)) {
                this.chartContainerModel.filters[1].selectedData.push(type)
              }
            })
          }
        } else {
          initialLoad = false
        }
        // rango de tiempo
        if (config.data.selectedDateAndTimeRange && this.chartContainerModel.dateAndTimeRanges.find(range => range.id === config.data.selectedDateAndTimeRange)) {
          this.chartContainerModel.selectedDateAndTimeRange = config.data.selectedDateAndTimeRange
          // custom
          if (config.data.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
            if (config.data.selectedDateAndTimeRangeCustomType &&
              config.data.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
              this.chartContainerModel.selectedDateAndTimeRangeCustomType = config.data.selectedDateAndTimeRangeCustomType
              if (config.data.sinceDate && config.data.toDate) {
                this.chartContainerModel.sinceDate = config.data.sinceDate
                this.chartContainerModel.toDate = config.data.toDate
              } else {
                initialLoad = false
              }
            } else if (config.data.selectedDateAndTimeRangeCustomType &&
              config.data.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
              this.chartContainerModel.selectedDateAndTimeRangeCustomType = config.data.selectedDateAndTimeRangeCustomType
              if (config.data.sinceDate && config.data.toDate && config.data.sinceTime && config.data.toTime) {
                this.chartContainerModel.sinceDate = config.data.sinceDate
                this.chartContainerModel.sinceTime = config.data.sinceTime
                this.chartContainerModel.toDate = config.data.toDate
                this.chartContainerModel.toTime = config.data.toTime
              } else {
                initialLoad = false
              }
            } else {
              initialLoad = false
            }
          }
        }
      } else { // CARGA SIN CONFIG
        // para selector CON actividad
        if (this.chartContainerModel.filters[0].data.length > 1) {
          await this.activityFilterCallback()
          // para selector SIN actividad
        } else if (this.chartContainerModel.filters[0].data.length === 1 && this.chartContainerModel.filters[0].data[0].key === 'NO_ACTIVITY') {
          this.chartContainerModel.filters[0].selectedData.cleanAndUpdate([this.chartContainerModel.filters[0].data[0]])
        } else {
          initialLoad = false
        }
      }
      return initialLoad
    }
  },
  watch: {
    'chartContainerModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.selectedDateAndTimeRange': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.selectedDateAndTimeRangeCustomType': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.sinceDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.sinceTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.toDate': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    },
    'chartContainerModel.toTime': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
