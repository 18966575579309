// import workedAreaReportApi from '@/api/workedAreaReport.api'
import WorkedAreaTotalComponent from './workedAreaTotal/workedAreaTotalComponent.vue'
import WorkedAreaWorkFrontComponent from './workedAreaWorkFront/workedAreaWorkFrontComponent.vue'
import WorkedAreaFarmComponent from './workedAreaFarm/workedAreaFarmComponent.vue'
// import { SECTOR_KEY } from '@colven/common-domain-lib/lib'

export default {
  name: 'DriveUnitDashboard',
  props: {
  },
  components: {
    WorkedAreaTotalComponent,
    WorkedAreaWorkFrontComponent,
    WorkedAreaFarmComponent
  },
  data: () => ({
    workedAreaSector: null
  }),
  computed: {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  methods: {
  }
}
